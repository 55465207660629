import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FAF9F8',
      neutralLight3: '#EFECE9',
      neutralDark3: '#988A6F',
      neutralDark2: '#535B5D',
      neutralDark1: '#000000',
      primaryLight: '#A6B9BD',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'ivypresto-display', sans-serif",
    paragraph: "'roc-grotesk', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
  font-weight: 300;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.neutralDark3};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.8125rem;
    letter-spacing: 0.26em;
    line-height: 1.0625rem;
    text-transform: uppercase;
  `,
  title: {
    large: css`
      ${titleStyle}
      font-size: 3.25rem;
      line-height: 3.875rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3.75rem;
      line-height: 4.25rem;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.625rem;
      }
    `,
    normal: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.875rem;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 2.25rem;
      line-height: 2.625rem;

      @media (max-width: 1199px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
      }
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark2};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1.0625rem;
    line-height: 2.125rem;

    @media (max-width: 1199px) {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export type Datepicker = typeof datepicker

export const datepicker = css`
  .react-datepicker {
    min-width: 20rem;
    font-family: ${theme.fontFamily.paragraph};
    background-color: ${theme.colors.variants.neutralLight3};
    color: ${theme.colors.variants.neutralDark2};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralLight5, 0.8)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${theme.fontFamily.paragraph};
    color: ${theme.colors.variants.neutralDark2};
  }

  .react-datepicker__day-name {
    font-family: ${theme.fontFamily.paragraph};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${theme.colors.variants.neutralDark2};
    color: ${theme.colors.variants.neutralLight5};
  }

  .react-datepicker__day:focus {
    background-color: ${theme.colors.variants.neutralDark2} !important;
    color: ${theme.colors.variants.neutralLight5} !important;
  }

  .react-datepicker__day--today {
    &:after {
      border-color: ${theme.colors.variants.neutralDark2};
    }
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 2rem;
      background-color: transparent;
      background-image: url('/svg/calendar.svg');
      background-repeat: no-repeat;
      background-position: right center;
      border-bottom: 0.0625rem solid
        ${rgba(theme.colors.variants.neutralDark2, 0.4)};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 0.9375rem;
      font-weight: 500;
      color: ${theme.colors.variants.neutralDark2};
      line-height: 1.5625rem;
      &.error {
        border-color: ${theme.colors.variants.danger};
      }

      &::-webkit-input-placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 1;
      }
      &::-moz-placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 1;
      }
      &::placeholder {
        color: ${theme.colors.variants.neutralDark2};
        opacity: 1;
      }
    }
  }
`

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
